import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  Approver,
  GetAvatar,
  GetCcUsers,
  GetUsersLight,
  GetUsersLightAdditional,
  GetUsersStrengthGroup,
  RegisterUser,
  UserFeedAppreciator,
  UserPhoto,
  UserProfile,
  UserStrength,
} from '../../utility/model/user-profile';
import { RewardCategoryList } from '../../utility/model/reward-category';
import {
  Reward,
  RewardRating,
  RewardRatingList,
} from '../../utility/model/reward';
import {
  DashboardData,
  Event,
  EventGallery,
} from '../../utility/model/dashboard-data';
import { RewardCategory } from '../../utility/model/reward-category';
import { RewardSearch } from '../../utility/model/search-rewards';
import { NotificationList } from '../../utility/model/notification-list';
import { RedemptionList } from '../../utility/model/redemption-list';
import {
  PhysicalAddress,
  PhysicalAddressList,
} from '../../utility/model/physical-address';
import { ExternalTokenAuth, TokenAuth } from '../../utility/model/token-auth';
import {
  RedemptionResponse,
  RedemptionTnC,
} from '../../utility/model/redemption-response';
import {
  ExpiringTransaction,
  FinanceTransactionsResponse,
} from '../../utility/model/finance-transactions-response';
import {
  FinanceTransactionsPointCategories,
  FinanceTransactionsPointSummaryResponse,
} from '../../utility/model/finance-transactions-point-summary-response';
import {
  SpecialDealItem,
  SpecialDealResponse,
} from '../../utility/model/special-deal';
import { CalculatePoint } from '../../utility/model/calculate-point';
import { PaymentIntentResp } from '../../utility/model/paymentIntent';
import { PaymentConfirmationResp } from '../../utility/model/payment-confirmation';
import { DeliveryFeeResp } from '../../utility/model/delivery-fee';
import { AllRes, Announcement, Greeting } from '../../utility/model/calendar';
import {
  AddPollParam,
  AddPostParam,
  GetPostAppreciation,
  GetPostPollList,
  MyBadges,
  MyCoreValues,
  PinReq,
  PostAppreciate,
  PostPollResult,
  ReportAbuseResp,
  ReportAbuseResp2,
} from '../../utility/model/post-poll';
import {
  GetCommentList,
  GetCommentReactionResp,
} from '../../utility/model/comment';
import { ECardCategoryList, ECardList } from '../../utility/model/e-card';
import {
  GetOrganizations,
  Organization,
  OrganizationCountry,
  OrganizationSettings,
} from '../../utility/model/organization';
import {
  ApprovalRes,
  GetNominationCategory,
  GetNominationDepartment,
  GetNominationHistory,
  GetNominationUser,
  GetNominationsResp,
  NominateResp,
  NominationCategory,
  NominationItemB,
  NominationQuestionResp,
  NominationUser,
} from '../../utility/model/nomination';
import { GetCustomerSupport } from '../../utility/model/customer-support';
import { FlagResp } from '../../utility/model/appreciation';
import { AxiosResponse } from 'axios';
import { GetCartResp } from 'src/app/utility/model/cart';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tr } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  isBayer: boolean = environment.settings.app === 'bayer';
  appName = environment.settings.app;

  constructor(private http: HttpService) {}

  public async login(
    postData: any,
    loginViaUserName: boolean,
  ): Promise<TokenAuth> {
    return await this.http
      .post('profiles/api/token-auth/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        if (loginViaUserName) {
          const data = x?.data as TokenAuth;
          return {
            ...data,
            currency: data.currency || environment.settings.defaultCurrency,
          };
        } else {
          return x?.data;
        }
      });
  }

  public async loginExternal(postData: any): Promise<ExternalTokenAuth> {
    return await this.http
      .post('profiles/api/external-token-auth/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async logOut(): Promise<any> {
    return await this.http.get('profiles/api/logout/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async forgotPassword(body: object): Promise<any> {
    return await this.http
      .post('profiles/api/users/reset_password/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async changePassword(body: object): Promise<any> {
    return await this.http
      .post('profiles/api/users/current/set_password/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getUserProfile(): Promise<UserProfile> {
    return await this.http
      .get('profiles/api/users/current/?affiliated=1')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  // useless
  public async getAppreciationUsers(searchKey: string): Promise<GetUsersLight> {
    return await this.http
      .get(`profiles/api/users/?affiliated=1&search=${searchKey}&appreciation`)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAvatar(): Promise<GetAvatar> {
    return await this.http.get('profiles/api/avatars/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async updateUserProfile(
    body: object,
    ignoreJsonContentType = false,
  ): Promise<UserProfile> {
    return await this.http
      .put('profiles/api/users/current/', body, ignoreJsonContentType)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getUser1(id: number): Promise<UserProfile> {
    return await this.http.get('profiles/api/users/' + id + '/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getUserPrivacySetting(): Promise<UserProfile> {
    return await this.http
      .get('profiles/api/users/current/privacy_settings/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async updateUserPrivacySetting(
    body: UserProfile,
  ): Promise<UserProfile> {
    return await this.http
      .post('profiles/api/users/current/privacy_settings/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async ccUser(userPk: string): Promise<GetCcUsers> {
    return !userPk
      ? {}
      : await this.http
          .get(`profiles/api/users_light/line_managers/?users=${userPk}`)
          .then((res) => {
            const x = res as AxiosResponse<any, any>;
            return x?.data;
          });
  }

  public async approverHierarchy(
    nominator: number,
    nominee: number,
    category: number,
    badge: number,
  ): Promise<Approver[]> {
    return await this.http
      .get(
        'nominations/api/approver_hierarchy/?nominator=' +
          nominator +
          '&nominee=' +
          nominee +
          '&category=' +
          category +
          '&badge=' +
          badge,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async usersLight(query: string): Promise<GetUsersLight> {
    return await this.http
      .get('profiles/api/users_light/?' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async usersLightAdditional(): Promise<GetUsersLightAdditional> {
    return await this.http
      .get('profiles/api/users_light/additional_data/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async usersListGroupByOrg(query: string): Promise<GetUsersLight> {
    return await this.http
      .get('/profiles/api/users_light/group_by_org/?' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editUser(id: number, body: object): Promise<UserProfile> {
    return await this.http
      .put('profiles/api/users/' + id + '/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async selfRegistration(
    postData: any,
    ignoreJsonContentType = false,
    multipartFormData = false,
  ): Promise<RegisterUser> {
    let queryParams = {};
    if (environment.settings.registerWithEmployeeId) {
      queryParams = {
        register_with_employee_id: true,
      };
    } else if (environment.settings.registerWithLineId) {
      queryParams = {
        register_with_line_id: true,
      };
    }

    const query = new URLSearchParams(queryParams).toString();

    return await this.http
      .post(
        'profiles/api/register/?' + query,
        postData,
        ignoreJsonContentType,
        multipartFormData,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async registrationConfirm(postData: any): Promise<RegisterUser> {
    return await this.http
      .post('profiles/api/confirm-registration/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async checkAccountActivation(
    uid: any,
    token: any,
    username: any,
  ): Promise<any> {
    return await this.http
      .get('profiles/activate-user/' + uid + '/' + token + '/' + username + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async accountActivation(
    uid: any,
    token: any,
    username: any,
    postData: any,
  ): Promise<any> {
    return await this.http
      .post(
        'profiles/activate-user/' + uid + '/' + token + '/' + username + '/',
        postData,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardCategoryList(): Promise<RewardCategoryList> {
    return (
      await this.http.get('rewards/api/browse_reward_categories/', {
        cache: true,
      })
    )?.data;
  }

  public async getRewardCategoryAndSubCategoryList(): Promise<RewardCategoryList> {
    return (
      await this.http.get('rewards/api/categories_and_sub_categories/', {
        cache: true,
      })
    )?.data;
  }

  public async getRewardList(): Promise<RewardCategoryList> {
    return await this.http
      .get('rewards/api/browse_reward_categories/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardDetail(id: number): Promise<Reward> {
    return await this.http
      .get('rewards/api/rewards/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardRating(id: number): Promise<RewardRatingList> {
    return await this.http
      .get('rating/api/rating/?redemption__reward=' + id)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async saveRewardRating(postData: object): Promise<RewardRating> {
    return await this.http.post('rating/api/rating/', postData).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async updateRewardRating(
    id: number,
    postData: object,
  ): Promise<RewardRating> {
    return await this.http
      .put('rating/api/rating/' + id + '/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getDashboardData(
    topRewardsCategorywise: boolean,
  ): Promise<DashboardData> {
    return await this.http
      .get(
        'events/api/dashboard_data/' +
          (topRewardsCategorywise ? '?redemption_type_wise=true' : ''),
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPointsSummary(): Promise<FinanceTransactionsPointSummaryResponse> {
    return await this.http
      .get('finance/api/transactions/points_summary/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAsyncBayerPoints(): Promise<any> {
    return await this.http
      .get('/profiles/api/sync_user_points/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPointsSummaryLite(): Promise<any> {
    return await this.http
      .get('finance/api/transactions/points_summary_lite/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPointCategories(
    period: string,
  ): Promise<FinanceTransactionsPointCategories[]> {
    return await this.http
      .get('finance/api/transactions/point_categories/?period=' + period)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getTransactionsHistory(
    params: string,
  ): Promise<FinanceTransactionsResponse> {
    return await this.http
      .get('finance/api/transactions/?' + params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getExpiringTransactions(
    params: string,
  ): Promise<ExpiringTransaction[]> {
    return (await this.http.get('finance/api/expiring_points/?' + params))
      ?.data;
  }

  public async yearlyRedemption(params: string): Promise<RedemptionList> {
    return await this.http
      .get('redemptions/api/redemptions/yearly/?' + params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getTransactionYears(): Promise<any> {
    return (await this.http.get('finance/api/user_transactions_year/'))?.data;
  }

  public async getRedemptionYears(): Promise<any> {
    return (await this.http.get('redemptions/api/user_redemption_years/'))
      ?.data;
  }

  public async getMyWalletRedemptionsList(
    params: string,
  ): Promise<RedemptionList> {
    return await this.http
      .get('redemptions/api/redemptions/' + params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardCategory(params: string): Promise<RewardCategory[]> {
    return await this.http
      .get('rewards/api/categories/?' + params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardSearch(
    params: string,
    initiateApiCall?: boolean,
  ): Promise<RewardSearch> {
    return await this.http
      .get('rewards/api/rewards/?' + params, {
        cache: true,
        initiateApiCall: initiateApiCall,
      })
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNotifications(query: string = ''): Promise<NotificationList> {
    return await this.http
      .get('push_notifications/api/notifications/?' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async mark1NotificationsAsRead(id: number): Promise<any> {
    return await this.http
      .put('push_notifications/api/notifications/' + id + '/mark_read/', null)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async markAllNotificationsAsRead(): Promise<any> {
    return await this.http
      .put('push_notifications/api/notifications/mark_all_read/', null)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async delete1Notification(id: number): Promise<any> {
    return await this.http
      .delete('push_notifications/api/notifications/' + id + '/', false)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async deleteAllNotification(): Promise<any> {
    return await this.http
      .get('push_notifications/api/notifications/delete_all/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async redemptionTnC(): Promise<RedemptionTnC> {
    return await this.http
      .get('redemptions/api/redemption_t_n_c/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async redemption(postData: object): Promise<RedemptionResponse> {
    return await this.http
      .post('redemptions/api/redemptions/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async updateBayerPoints(redemptionId: number): Promise<any> {
    if (this.isBayer) {
      const postData = { redemption: redemptionId };
      return await this.http
        .post('/profiles/api/update_bayer_points/', postData)
        .then((res) => {
          const x = res as AxiosResponse<any, any>;
          return x?.data;
        });
    }
  }

  public async getRedemptionDetailsById(pk: number): Promise<Reward> {
    return await this.http
      .get('redemptions/api/redemptions/' + pk + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async markUsedRedemptionStatus(pk: number): Promise<Reward> {
    return await this.http
      .post('redemptions/api/redemptions/' + pk + '/mark_used/', {})
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async updateRedemptionStatus(
    pk: number,
    status: number,
  ): Promise<Reward> {
    return await this.http
      .put('redemptions/api/redemptions/' + pk + '/', {
        voucher_status: status,
      })
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAddress(): Promise<PhysicalAddressList> {
    return await this.http.get('profiles/api/physical_address/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getAddress1(id: number): Promise<PhysicalAddress> {
    return await this.http
      .get('profiles/api/physical_address/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addAddress(body: object): Promise<PhysicalAddress> {
    return await this.http
      .post('profiles/api/physical_address/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editAddress(id: number, body: object): Promise<PhysicalAddress> {
    return await this.http
      .put('profiles/api/physical_address/' + id + '/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async deleteAddress(id: number): Promise<any> {
    return await this.http
      .delete('profiles/api/physical_address/' + id + '/delete/', true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async markAddressAsDefault(id: number): Promise<PhysicalAddress> {
    return await this.http
      .post('profiles/api/physical_address/' + id + '/mark_default/', null)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async calculatePoints(postData: object): Promise<CalculatePoint> {
    return await this.http
      .post('finance/api/calculate_points/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async paymentCheckout(postData: object): Promise<PaymentIntentResp> {
    return await this.http
      .post('shopping/create-payment-intent/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async paymentConfirmation(
    chargeId: string,
  ): Promise<PaymentConfirmationResp> {
    return await this.http
      .get('shopping/confirm-payment/?charge_id=' + chargeId)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async specialDeal(): Promise<SpecialDealResponse> {
    return await this.http.get('rewards/api/specialdeals/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async specialDealDetail(
    idOrSlug: number | string,
  ): Promise<SpecialDealItem> {
    return await this.http
      .get('rewards/api/specialdeals/' + idOrSlug + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async consolidateDeliveryFee(
    id: number,
    query: string,
  ): Promise<DeliveryFeeResp> {
    return await this.http
      .get('rewards/api/rewards/' + id + '/consolidate_delivery_fee/?' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getOrganisationDetails(
    organizationName: string,
  ): Promise<GetOrganizations> {
    return await this.http
      .get('profiles/api/organizations/?sub_domain=' + organizationName)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getUserDetails(): Promise<TokenAuth> {
    return await this.http.get('profiles/api/user-info/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      const data = x?.data as TokenAuth;
      return {
        ...data,
        currency: data.currency || environment.settings.defaultCurrency,
      };
    });
  }

  public async confirmUserOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/twofa_confirm/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async resendUserOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/twofa_resend/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async sendAnonymousUserOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/users/resend_anonymous_user_otp/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async sendMobileOtp(queryParams: any, postData: object): Promise<any> {
    const query = new URLSearchParams(queryParams).toString();
    return await this.http
      .post('profiles/api/send_otp_to_user/?' + query, postData, true, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async resendMobileOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/resend_otp_to_anonymous_user/', postData, true, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async verifyMobileOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/verify_anonymous_otp/', postData, true, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async verifyOtp(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/verify_otp/', postData, true, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getCalendarAll(query: string): Promise<AllRes[]> {
    return await this.http
      .get('events/api/all/monthly/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getCalendarAnnouncement(query: string): Promise<Announcement[]> {
    return await this.http
      .get('events/api/announcements/monthly/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getCalendarEvent(query: string): Promise<Event[]> {
    return await this.http
      .get('events/api/events/monthly/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getCalendarGreeting(query: string): Promise<Greeting[]> {
    return await this.http
      .get('events/api/greetings/monthly/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAnnouncementDetail(id: number): Promise<Announcement> {
    return await this.http
      .get('events/api/announcements/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getEventDetail(id: number): Promise<Event> {
    return await this.http.get('events/api/events/' + id + '/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async postEventDetail(payload: object): Promise<Event> {
    return await this.http
      .post('events/api/events/message/', payload, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async postEventDetailRsvp(
    id: number,
    payload: object,
  ): Promise<Event> {
    return await this.http
      .post('events/api/events/' + id + '/rsvp/', payload)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getGreetingDetail(id: number): Promise<Greeting> {
    return await this.http
      .get('events/api/greetings/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getEventPhotoGallery(eventId: number): Promise<EventGallery> {
    return await this.http
      .get('photo_galleries/api/event_gallery/' + eventId + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async postEventPhotoGallery(
    eventId: number,
    payload: object,
  ): Promise<EventGallery> {
    return await this.http
      .post(
        'photo_galleries/api/event_gallery/' + eventId + '/upload/',
        payload,
        true,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editEventImageCaption(
    photoId: number,
    payload: object,
  ): Promise<UserPhoto> {
    return await this.http
      .put('photo_galleries/api/photos/' + photoId + '/', payload)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async deleteEventImage(photoId: number) {
    return await this.http
      .delete('photo_galleries/api/photos/' + photoId + '/', false)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPostPolls(
    mode: 'post' | 'organization_recognition' | 'recent_recognition' = 'post',
    query: string = '',
  ): Promise<GetPostPollList> {
    let url = 'feeds/api/posts/?';
    if (mode === 'recent_recognition') {
      url = 'feeds/api/user_feed/recent_recognitions/?';
    }
    if (mode === 'organization_recognition') {
      url = 'feeds/api/user_feed/organization_recognitions/?';
    }
    return await this.http.get(url + query).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getUserFeed(
    feed: 'received' | 'given' | 'approvals',
    query: string = '',
  ): Promise<GetPostPollList> {
    return await this.http
      .get('feeds/api/user_feed/?feed=' + feed + (!query ? '' : '&') + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getMyNominations(qs: string): Promise<GetPostPollList> {
    return await this.http.get('feeds/api/user_feed/' + qs).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getNominationDetails(id: number): Promise<PostPollResult> {
    return await this.http
      .get('feeds/api/user_feed/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPosts(qs: string): Promise<GetPostPollList> {
    return await this.http.get('feeds/api/posts/' + qs).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getPostDetail(id: number): Promise<PostPollResult> {
    return await this.http.get('feeds/api/posts/' + id + '/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getPostPollCommentList(
    postPollId: number,
    query: string = '',
  ): Promise<GetCommentList> {
    return await this.http
      .get('feeds/api/posts/' + postPollId + '/comments/?' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addPost(
    body: AddPostParam | FormData,
    ignoreJsonContentType = false,
  ): Promise<PostPollResult> {
    return await this.http
      .post('feeds/api/posts/', body, ignoreJsonContentType)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addPoll(body: AddPollParam): Promise<PostPollResult> {
    return await this.http
      .post('feeds/api/posts/create_poll/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async votePoll(id: number, body: object): Promise<PostPollResult> {
    return await this.http
      .post('feeds/api/posts/' + id + '/vote/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editPost(
    feedId: number,
    body: object,
    ignoreJsonContentType = false,
  ): Promise<PostPollResult> {
    return await this.http
      .put('feeds/api/posts/' + feedId + '/', body, ignoreJsonContentType)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async deletePostPoll(feedId: number, body: any): Promise<any> {
    return await this.http
      .delete('feeds/api/posts/' + feedId + '/', false, body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async sendAppreciation(postData: object): Promise<any> {
    return await this.http
      .post('profiles/api/strength_recognition?appreciation=1', postData, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async nominationCategories(): Promise<GetNominationCategory> {
    return await this.http.get('nominations/api/categories/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async nominationCategory(id: number): Promise<NominationCategory> {
    return await this.http
      .get('nominations/api/categories/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNominations(qs = ''): Promise<GetNominationsResp> {
    return await this.http
      .get('nominations/api/nominations/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNomination(id: number): Promise<NominationItemB> {
    return await this.http
      .get('nominations/api/nominations/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async nominate(postData: object): Promise<NominateResp> {
    return await this.http
      .post('nominations/api/nominations/', postData, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNominationDept(id: number): Promise<GetNominationDepartment> {
    return await this.http
      .get('profiles/api/departments/?nomination_category=' + id)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNominationUsers(qs: string): Promise<GetNominationUser> {
    return await this.http
      .get('nominations/api/nominations_users/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async get1NominationUser(id: number): Promise<NominationUser> {
    return await this.http
      .get('nominations/api/nominations_users/' + id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAppreciationDetails(id: number): Promise<PostPollResult> {
    return await this.http.get('feeds/api/posts/' + id + '/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getECardCategories(): Promise<ECardCategoryList> {
    return await this.http.get('feeds/api/ecard_category/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getECards(pk: number | null = null): Promise<ECardList> {
    const category = !!pk ? `?category=${pk}` : '';
    return await this.http.get(`feeds/api/ecard/${category}`).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async onAddOrEditFeedComment(
    isCommentEditing: boolean,
    id: number,
    postData: object,
  ): Promise<any> {
    if (isCommentEditing == true) {
      // means edit existing comment
      return await this.http
        .patch('feeds/api/comments/' + id + '/', postData)
        .then((res) => (res as AxiosResponse<any, any>)?.data);
    } else {
      // means add new comment
      return await this.http
        .post('feeds/api/posts/' + id + '/comments/', postData)
        .then((res) => (res as AxiosResponse<any, any>)?.data);
    }
  }

  public async deleteFeedComment(id: number): Promise<any> {
    return await this.http
      .delete('feeds/api/comments/' + id + '/', false)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async reactionOnFeedPost(
    id: number,
    postData: object,
  ): Promise<PostAppreciate> {
    return await this.http
      .post('feeds/api/posts/' + id + '/appreciate/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async reactionOnComment(
    reactionType: string,
    id: number,
  ): Promise<GetCommentReactionResp> {
    return await this.http
      .post(
        'feeds/api/comments/' + id + '/' + reactionType.toLowerCase() + '/',
        {},
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getPostReactionUserList(
    postId: number,
    queryString: string,
  ): Promise<GetPostAppreciation> {
    return await this.http
      .get('feeds/api/posts/' + postId + '/post_appreciations/?' + queryString)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async pinPostPoll(body: PinReq): Promise<PostPollResult> {
    return await this.http
      .post('feeds/api/posts/pinned_post/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async reportAbusePostPoll(
    id: number,
    body: object,
  ): Promise<ReportAbuseResp> {
    return await this.http
      .post('feeds/api/posts/' + id + '/flag/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async reportAbusePostPoll2(
    id: number,
    body: object,
  ): Promise<ReportAbuseResp2> {
    return await this.http
      .post('profiles/api/recognition-feed/' + id + '/report_abuse/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async flagAppreciation(id: number, body: object): Promise<FlagResp> {
    return await this.http
      .post('feeds/api/posts/' + id + '/flag/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async userStrength(qs = ''): Promise<UserStrength | null> {
    return await this.http
      .get('profiles/api/user_strengths/' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async userStrengthGroup(
    qs = '',
  ): Promise<GetUsersStrengthGroup | null> {
    return await this.http
      .get('profiles/api/user_strengths_group/' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getOrganizationCountries(): Promise<OrganizationCountry[]> {
    return await this.http
      .get('profiles/api/organization_country/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getOrganizations(country = ''): Promise<GetOrganizations> {
    let qs = 'affiliated=1&appreciation=true';
    if (!!country) {
      qs += '&country=' + country;
    }
    return await this.http
      .get('profiles/api/organizations/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAllOrganizationSettings(): Promise<OrganizationSettings> {
    return await this.http.get('profiles/api/org_settings/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getOrganization1(pk: number): Promise<Organization> {
    if (!pk) {
      return null;
    }
    return await this.http
      .get(`profiles/api/organizations/${pk}/?affiliated=1&appreciation`)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAppSupport(): Promise<GetCustomerSupport> {
    return await this.http.get(`profiles/api/customersupport/`).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async searchCcUsers(searchKey: string): Promise<UserProfile[]> {
    return await this.http
      .get(`feeds/api/search_users/?term=${searchKey}`)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNominationHistory(id: number): Promise<GetNominationHistory> {
    return await this.http
      .get('nominations/api/nominations_history/?nomination=' + id)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async approveNomination(
    id: number,
    postData: FormData,
    qs = '',
  ): Promise<ApprovalRes> {
    return await this.http
      .post(
        'nominations/api/nominations/' + id + '/update_status/' + qs,
        postData,
        true,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addToCart(postData: object): Promise<any> {
    return await this.http
      .post('redemptions/api/cart/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getCartDetails(): Promise<GetCartResp> {
    return await this.http.get('redemptions/api/cart/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getUserFeedAppreciator(
    qs: string,
  ): Promise<UserFeedAppreciator> {
    return await this.http
      .get('feeds/api/user_feed/appreciated_by/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getMyCoreValues(qs: string): Promise<MyCoreValues> {
    return await this.http
      .get('feeds/api/user_feed/strengths/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getMyBadges(qs: string): Promise<MyBadges> {
    return await this.http
      .get('feeds/api/user_feed/badges/?' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async updateCart(cartId: number, postData: object): Promise<any> {
    return await this.http
      .put('redemptions/api/cart/' + cartId + '/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async redeemCart(postData: object): Promise<any> {
    return await this.http
      .post('redemptions/api/cart/redeem_cart/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async checkOrderCeleryProgress(task_id: string): Promise<any> {
    return await this.http
      .get('/celery-progress/' + task_id + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAmplifyTxt(params: object): Promise<any> {
    return await this.http
      .post('feeds/api/inspire_me/amplify_core_value_recognition/', params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async regenerateAmplifyTxt(params: object): Promise<any> {
    return await this.http
      .post('feeds/api/inspire_me/edit_tone/', params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAmplifyPost(params: object): Promise<any> {
    return await this.http
      .post('feeds/api/inspire_me/amplify_content_post/', params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getAmplifyPoll(params: object): Promise<any> {
    return await this.http
      .post('feeds/api/inspire_me/amplify_content_poll/', params)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRecognitionHeroes(
    heroType: string,
    recognitionType: string,
  ): Promise<any> {
    return await this.http
      .get(
        'profiles/api/recognition-heros/?type=' +
          heroType +
          '&recognition_type=' +
          recognitionType,
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getNominationQuestion(
    category: number,
    page = 1,
  ): Promise<NominationQuestionResp> {
    return !category
      ? {}
      : await this.http
          .get(
            'nominations/api/nominations_questions/?category=' +
              category +
              '&page=' +
              page,
          )
          .then((res) => {
            const x = res as AxiosResponse<any, any>;
            return x?.data;
          });
  }

  public async getSalesReceipts(query: any): Promise<any> {
    return await this.http.get('profiles/api/receipts/' + query).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getSalesReceiptDetails(query: any): Promise<any> {
    return await this.http.get('profiles/api/receipts/' + query).then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getBrandCategories(): Promise<any> {
    return await this.http.get('finance/api/brand_categories/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getBrandSubCategories(id: number): Promise<any> {
    return await this.http
      .get('finance/api/sub_categories/?brand_category=' + id)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addSalesReceipt(postData: any): Promise<any> {
    return await this.http
      .post('profiles/api/multiple_receipts/', postData, false, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editSalesReceipt(
    receiptId: number,
    postData: any,
  ): Promise<any> {
    return await this.http
      .patch(
        'profiles/api/multiple_receipts/' + receiptId + '/',
        postData,
        false,
        true,
      )
      .then((res) => (res as AxiosResponse<any, any>)?.data);
  }

  public async saveReceiptDraft(postData: any): Promise<any> {
    return await this.http
      .post('api/receipts-new/drafts/', postData, false, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getReceiptDraft(draftId: string): Promise<any> {
    return await this.http
      .get('api/receipts-new/drafts/?draft_id=' + draftId)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getBenefitReceipts(query: any): Promise<any> {
    return await this.http
      .get('profiles/api/benefit_receipts/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getDependentsList(userId: any): Promise<any> {
    return await this.http
      .get('/profiles/api/dependent/?user_id=' + userId)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async addBenefitReceipt(postData: any): Promise<any> {
    return await this.http
      .post('profiles/api/benefit_receipts/', postData, false, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getBenefitCategory(): Promise<any> {
    return await this.http
      .get('benefits/api/benefit_category/?for_org_self=false')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getBenefitOption(categoryId: number): Promise<any> {
    return await this.http
      .get(
        'benefits/api/benefit_option/?benefit_category_new=' +
          categoryId +
          '&for_user=true',
      )
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getBenefitReceiptDetails(receiptId: number): Promise<any> {
    return await this.http
      .get('profiles/api/benefit_receipts/' + receiptId + '/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async editBenefitReceipt(
    receiptId: number,
    postData: any,
  ): Promise<any> {
    return await this.http
      .patch(
        'profiles/api/benefit_receipts/' + receiptId + '/',
        postData,
        false,
        true,
      )
      .then((res) => (res as AxiosResponse<any, any>)?.data);
  }

  public async sendEmailOtp(postData: object) {
    return await this.http
      .post('profiles/api/send_email_otp/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async verifyEmailOtp(postData: object) {
    return await this.http
      .post('profiles/api/verify_email_otp/', postData)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public clearCacheStorage() {
    return this.http.clearStorage();
  }

  public async getSubUsers(): Promise<any> {
    return await this.http.get('profiles/api/sub_users/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getDeepLinkToken() {
    return await this.http.get('profiles/api/deeplink_token').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async getUserToken(token: string) {
    return await this.http
      .get('profiles/api/user_token?deeplink_token=' + token)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getProductChoices() {
    return await this.http.get('profiles/api/product_choices/').then((res) => {
      const x = res as AxiosResponse<any, any>;
      return x?.data;
    });
  }

  public async resetPassword(body: any) {
    return await this.http
      .post('profiles/api/change_password/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getOrgDepts(qs) {
    return await this.http
      .get('/profiles/api/org_departments/' + qs)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  // There are multiple instances of this API with different query param, need to update this
  public async getOrgDept(query: any): Promise<any> {
    return await this.http
      .get('profiles/api/departments/' + query)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async resendRegistrationOtp(postData: any): Promise<any> {
    return await this.http
      .post('profiles/api/users/resend_registration_otp/', postData, true, true)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async krisFlyerValidation(body: any) {
    return await this.http
      .post('profiles/api/calculate_check_digit/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getSessionTimer() {
    return await this.http
      .get('profiles/api/darwin_message_id_timeout/')
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async getRewardPreferenceCategory() {
    if (this.appName == 'pru') {
      return await this.http
        .get(`profiles/api/reward_sub_category_preference/`)
        .then((res) => {
          const x = res as AxiosResponse<any, any>;
          return x?.data;
        });
    }
  }

  public async createRewardPreferredSubCategory(body: any) {
    return await this.http
      .post('profiles/api/reward_sub_category_preference/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async updateRewardPreferredSubCategory(body: any) {
    return await this.http
      .put('profiles/api/reward_sub_category_preference/', body)
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }

  public async markAsFavorite(id: number) {
    return await this.http
      .post(`/rewards/api/rewards/${id}/mark_favorite/`, {})
      .then((res) => {
        const x = res as AxiosResponse<any, any>;
        return x?.data;
      });
  }
}
