import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrderConfirmationWithCartComponent } from 'src/app/redeem/modal/order-confirmation-with-cart/order-confirmation-with-cart.component';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { showError, showWarning } from 'src/app/utility/common-logic';
import { Cart } from 'src/app/utility/model/cart';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cart-header',
  templateUrl: './cart-header.component.html',
  styleUrls: ['./cart-header.component.scss'],
})
export class CartHeaderComponent implements OnInit {
  @Input() cartCount: number;
  @Input() cartDetails: any;
  @Input() cartItems: any;
  @Input() cartStatus: string;
  isShowCartPopup = false;
  allowPayment = environment.settings.allowPayment;
  cartEditable = true;

  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private shared: SharedService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.cartEditable = this.cartStatus == 'In process' ? false : true;
  }

  async onCheckout() {
    this.isShowCartPopup = false;
    if (
      !this.allowPayment &&
      this.cartDetails.amount_payable > this.cartDetails.user_points
    ) {
      showWarning(
        'msg.point.insufficient',
        this.toastr,
        this.translate,
        true,
        true,
      );
      return;
    }

    if (this.cartDetails?.all_voucher_cart === true) {
      const ref = this.dialog.open(OrderConfirmationWithCartComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: { cartDetails: this.cartDetails, addressChoice: null },
      });
      const sub = ref.componentInstance.onCartDataChange.subscribe(
        async (data) => {
          await this.getCartDetails();
        },
      );
      ref.afterClosed().subscribe(() => sub.unsubscribe());
    } else {
      const productReward = this.cartDetails?.results.filter(
        function (cartReward) {
          if (
            cartReward.reward_details.redemption_type === 'physical_product'
          ) {
            return cartReward.reward_details.pk;
          }
        },
      );

      const productRewardPk = productReward[0].reward_details.pk;
      this.router.navigate(['/reward', productRewardPk, 'address', 'select'], {
        queryParams: { qty: 1, cart: true },
      });
    }
  }

  gotoMyCart() {
    this.isShowCartPopup = false;
    if (this.router.url === '/my-cart') {
      this.shared.spinner = true;
      setTimeout(() => {
        this.shared.spinner = false;
      }, 1000);
    }
    this.router.navigate(['/my-cart']);
  }

  onShowCartSummary(data: any) {
    this.shared.cartDetails.next(this.cartDetails);
    this.shared.cartItems.next(data.cartItems);
    this.isShowCartPopup = data?.count > 0;
  }

  async getCartDetails() {
    await this.api
      .getCartDetails()
      .then((res: Cart) => {
        if (res) {
          this.cartDetails = res;
          this.shared.cartCount.next(res.count);
          this.shared.cartDetails.next(this.cartDetails);
        }
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.shared.spinner = false));
  }

  onClickedCart() {
    // const matDrawerContent = document.querySelector('.mat-drawer-content');
    const matDrawerContent = document.querySelector('.page-content');
    // const matDrawerBackup = document.querySelector('.mat-drawer-backdrop');
    if (this.isShowCartPopup) {
      // this.renderer.addClass(matDrawerBackup, 'mat-custom-backdrop');
      this.renderer.addClass(matDrawerContent, 'mat-custom-content');
    } else {
      // this.renderer.removeClass(matDrawerBackup, 'mat-custom-backdrop');
      this.renderer.removeClass(matDrawerContent, 'mat-custom-content');
    }
  }
}
